import React, {useEffect, useState} from "react";
import useFetchTalent from "../../hooks/useFetchTalent";
import {Skeleton} from "primereact/skeleton";
import Image from "../../assets/images/profile.svg";


const MessageCard = ({item, handleClick, isSelected}) => {
	const [avatar, setAvatar] = useState(null);

	// Destructure properties from the item object
	const {talent_id, recipient_name, request_details} = item;

	// Fetch talent data using custom hook
	const {celebrities, isLoading} = useFetchTalent(talent_id);

	// Destructure properties from the celebrities object
	const {first_name, last_name, profile_picture} = celebrities;
	const userName = `${first_name} ${last_name}`;

	// Update avatar image URL when profile_picture changes
	useEffect(() => {
		const imageUrl = `${
			profile_picture === null ? Image : profile_picture ?. replace("/home/novaet/", "https://")
		}`;
		setAvatar(imageUrl);
	}, [profile_picture]);


	return (
		<div onClick={handleClick}
			className={
				`text-white border-b-[.5px] border-white/20  flex gap-2 items-center p-3 cursor-pointer transition-all duration-300${
					isSelected ? "bg-accentPurple-dark/70  rounded-xl   hover:bg-accentPurple-dark/70" : "hover:bg-accentPurple-dark/30"
				} `
		}>
			{
			isLoading ? (
				<Skeleton shape="circle" size="4rem" className="bg-purple-400/50"></Skeleton>
			) : (
				<img src={avatar}
					alt={first_name}
					className="w-[4rem] h-[4rem] rounded-full object-cover "/>
			)
		}
			<div className="font-light flex flex-col gap-.5 ">
				{
				isLoading ? (
					<div className="flex flex-col items-start gap-.5 ">
						<Skeleton width="5rem" height="1rem" className="bg-purple-400/50 mb-2 rounded"></Skeleton>
						<Skeleton width="13rem" height="1rem" className="bg-purple-400/50 rounded"></Skeleton>
					</div>
				) : (
					<div className="flex flex-col gap-.5 ">
						<h4 className="text-sm">
							{userName}</h4>
						<h4 className="text-xs md:text-sm text-white/80">
							{recipient_name}: {
							request_details.slice(0, 20)
						}......
						</h4>
					</div>
				)
			} </div>
		</div>
	);
};

export default MessageCard;
