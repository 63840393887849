import { useEffect, useState } from "react";
import axios from "axios";

const CategoriesDetail = (id) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [celebrities, setCelebrities] = useState([]);

  useEffect(() => {
    const fetchCelebrities = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_CAT_URL}/talent/profile/${id}`
        );
        const data = response.data;
        setCelebrities(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
    };
    fetchCelebrities();
  }, []);

  return { celebrities, isLoading, error };
};
export default CategoriesDetail;
