import { FaSearch } from "react-icons/fa";
import { useState, useEffect, useRef } from "react";
import useSearch from "../../hooks/useSearch";
import SearchResult from "../layout/SearchResult";

const Search = ({ navOpen }) => {
  const [term, setTerm] = useState("");
  const [showResults, setShowResults] = useState(false); // New state variable
  const searchRef = useRef(null); // Ref for the search container element

  const handleChange = (e) => {
    setTerm(e.target.value);

    handleSearch();
    setShowResults(true); // Show results when typing
  };

  const handleFormSubmit = (e) => {
    handleSearch();
    e.preventDefault();
  };

  const handleOutsideClick = (e) => {
    if (searchRef.current && !searchRef.current.contains(e.target)) {
      setShowResults(false); // Hide results when clicking outside
    }
  };

  const { handleSearch, searchResult } = useSearch(term);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []); // Add/remove event listener on component mount/unmount

  return (
    <div className="relative" ref={searchRef}>
      <form
        action=""
        className="md:w-[15rem] lg:w-[18rem] xl:w-[24rem]"
        onSubmit={handleFormSubmit}
      >
        <div className="bg-white flex items-center gap-3 border-[1px] border-white rounded-full px-3 md:px-5 py-1.5 md:py-2 font-beVietnamPro">
          <FaSearch className="text-darkGray reflect text-sm cursor-pointer hover:scale-125 transition-all duration-500 ease-in-out" />

          <input
            type="search"
            placeholder="Search for celebrity..."
            value={term}
            onChange={handleChange}
            className="flex w-3/4 md:w-full items-center justify-between focus:outline-none text-sm font-Montserrat placeholder:text-xs"
          />
        </div>
      </form>
      <div className="w-28 bg-red-300">
        {showResults && term !== "" && (
          <SearchResult
            result={searchResult}
            showResult={setShowResults}
            navOpen={navOpen}
          />
        )}
      </div>
    </div>
  );
};

export default Search;
