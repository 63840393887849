import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/images/Logo.svg";
import Search from "../common/Search";
import NavDropDown from "../common/NavDropDown";
// import SearchResult from "./SearchResult";
// import useSearch from "../../hooks/useSearch";
const Navigation = () => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(false);

  const token = sessionStorage.getItem("token");

  const genericHamburgerLine = `h-[3px]  w-7 md:w-10 my-[3px] rounded-full bg-[#fff] transition ease transform duration-300`;

  useEffect(() => {
    const isActive = () => {
      window.scrollY > 0 ? setActive(true) : setActive(false);
      // scroll event handling logic
    };

    window.addEventListener("scroll", isActive);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", isActive);
    };
  }, []);
  // takes it to the top when we switch pages

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div
      className={`w-full h-[4.2rem] z-40 py-5 flex justify-center items-center
        ${
          active
            ? "bg-primaryBlack-medium z-40 shadow-xl fixed top-0 left-0  "
            : "bg-primaryBlack-dark fixed top-0 left-0  "
        }`}
    >
      <div className="w-11/12 max-w-[1440px] mx-auto flex justify-between items-center">
        <div className="flex items-center gap-16">
          <div className="flex items-center gap-4">
            <div className="lg:hidden">
              <button
                className="flex flex-col h-12  rounded justify-center items-center group"
                onClick={() => setIsOpen(!isOpen)}
              >
                <div
                  className={` transition-all duration-500 ${genericHamburgerLine}
           ${
             isOpen
               ? "rotate-[405deg] translate-y-[6px]   group-hover:scale-105"
               : " group-hover:-translate-y-[3px]"
           }`}
                />
                <div
                  className={`${genericHamburgerLine} ${
                    isOpen ? "opacity-0" : ""
                  }`}
                />
                <div
                  className={` transition-all duration-300 ${genericHamburgerLine} ${
                    isOpen
                      ? "-rotate-[45deg]  -translate-y-[12px]  group-hover:scale-105"
                      : " group-hover:translate-y-[2px]"
                  }`}
                />
              </button>
            </div>
            <NavLink to="/" className="lg:w-1/7cursor-pointer items-start flex">
              <div className="flex items-star">
                <img
                  className=" w-18 md:w-24 py-1"
                  src={Logo}
                  alt=""
                  onClick={() => setIsOpen(false)}
                />
              </div>
            </NavLink>
          </div>
          <div className="hidden lg:flex lg:gap-7 xl:gap-10  font-beVietnamPro font-semibold">
            <NavLink
              to="/"
              className="text-sm md:text-base relative cursor-pointer z-10 transition-all bg-[-100%] duration-500 bg-[length:200%_100%] text-transparent bg-text-gradient bg-clip-text hover:bg-[0%]"
            >
              Home
            </NavLink>
            <NavLink
              to="/categories"
              className="text-sm md:text-base relative cursor-pointer z-10 transition-all bg-[-100%] duration-500 bg-[length:200%_100%] text-transparent bg-text-gradient bg-clip-text hover:bg-[0%]"
            >
              Explore
            </NavLink>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://talent.nova.et/"
              className="text-sm md:text-base relative cursor-pointer z-10 transition-all bg-[-100%] duration-500 bg-[length:200%_100%] text-transparent bg-text-gradient bg-clip-text hover:bg-[0%]"
            >
              Join as a talent
            </a>
          </div>
        </div>
        <div className="hidden lg:flex items-center gap-7 font-montserrat justify-end">
          <Search navOpen={setIsOpen} />

          {token && token !== undefined ? (
            <div className="flex gap-5 items-center">
              <NavDropDown
                label="Categories"
                data={[
                  { name: "profile" },
                  { name: "message" },
                  { name: "request" },
                  { name: "logout" },
                ]}
              />
            </div>
          ) : (
            <div className="flex items-center gap-5">
              <NavLink
                to="/register"
                className="font-beVietnamPro text-sm md:text-base font-semibold relative cursor-pointer z-10 transition-all bg-[-100%] duration-500 bg-[length:200%_100%] text-transparent bg-text-gradient bg-clip-text hover:bg-[0%]"
              >
                Sign Up
              </NavLink>
              <NavLink
                to="/login"
                className="font-beVietnamPro text-sm md:text-base font-semibold relative cursor-pointer z-10 transition-all bg-[-100%] duration-500 bg-[length:200%_100%] text-transparent bg-text-gradient bg-clip-text hover:bg-[0%]"
              >
                Login
              </NavLink>
            </div>
          )}
        </div>

        {/* mobile menu */}
        <div className="lg:hidden">
          {token && token !== undefined ? (
            <NavDropDown
              label="Categories"
              data={[
                { name: "profile" },
                { name: "message" },
                { name: "request" },
                { name: "logout" },
              ]}
            />
          ) : (
            <NavLink
              to="/login"
              onClick={() => setIsOpen(false)}
              className="font-medium text-base relative cursor-pointer z-10 transition-all bg-[-100%] duration-500 bg-[length:200%_100%] text-transparent bg-text-gradient bg-clip-text hover:bg-[0%]"
            >
              Login
            </NavLink>
          )}
        </div>

        <div
          className={`z-50  table-scrollbar shadow-md transition-all duration-300 flex justify-start pt-32 absolute overflow-x-hidden h-screen translate-x-0  w-full md:w-3/4  top-[63.5px] bg-primaryBlack-dark/95
        } ${isOpen ? "lg:hidden left-0 " : "-left-full"}`}
        >
          <div className="flex flex-col  font-beVietnamPro items-start pl-9 text-base gap-5">
            <Search navOpen={setIsOpen} />
            <NavLink
              to="/"
              className="text-sm md:text-base relative cursor-pointer z-10 transition-all bg-[-100%] duration-500 bg-[length:200%_100%] text-transparent bg-text-gradient bg-clip-text hover:bg-[0%]"
              onClick={() => setIsOpen(false)}
            >
              Home
            </NavLink>
            <NavLink
              to="/categories"
              onClick={() => setIsOpen(false)}
              className="text-sm md:text-base relative cursor-pointer z-10 transition-all bg-[-100%] duration-500 bg-[length:200%_100%] text-transparent bg-text-gradient bg-clip-text hover:bg-[0%]"
            >
              Explore
            </NavLink>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://talent.nova.et/"
              onClick={() => setIsOpen(false)}
              className=" text-sm md:text-base relative cursor-pointer  transition-all bg-[-100%] duration-500 bg-[length:200%_100%] text-transparent bg-text-gradient bg-clip-text hover:bg-[0%]"
            >
              Join as a talent
            </a>
            {!(token && token !== undefined) && (
              <div className="flex flex-col font-beVietnamPro text-base gap-5">
                <NavLink
                  to="/register"
                  onClick={() => setIsOpen(false)}
                  className="text-sm md:text-base relative cursor-pointer z-10 transition-all bg-[-100%] duration-500 bg-[length:200%_100%] text-transparent bg-text-gradient bg-clip-text hover:bg-[0%]"
                >
                  Sign Up
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Navigation;
