import React from "react";
import {Link} from "react-router-dom";
import Logo from "../../assets/images/Logo.svg";
import Button from "../common/Button";
import {FaTwitterSquare, FaFacebook, FaInstagram} from "react-icons/fa";
const Footer = () => {
	return (
		<div className="bg-primaryBlack-medium py-8 ">
			<div className="w-11/12 max-w-[1440px]  mx-auto tracking-wide ">
				<Link to="/" className="lg:w-1/7 cursor-pointer items-start flex">
					<div className="flex items-star">
						<img className="w-20 md:w-24 py-1"
							src={Logo}
							alt=""/>
					</div>
				</Link>
				<div className="flex flex-col sm:flex-row gap-10 justify-between pt-2 md:pt-5 text-white/85  font-thin font-beVietnamPro  text-sm md:text-base ">
					<div className="flex flex-col gap-3 ">
						<p className="w-10/12  ">
							Access thousands of celebrities and request a personalized video
							              message for any occasion.
						</p>
						<div className="flex gap-3 md:gap-5">
							<Link to="/" className="transition-all duration-500 hover:text-accentBlue-medium">
								Home
							</Link>
							<Link to="/categories" className="transition-all duration-500  hover:text-accentBlue-medium">
								Explore
							</Link>
							<Link to="/register" className="transition-all duration-500  hover:text-accentBlue-medium">
								Signup
							</Link>
							<Link to="/login" className="transition-all duration-500  hover:text-accentBlue-medium">
								Login
							</Link>
						</div>
					</div>
					<div className="flex-col w-2/3 flex gap-3 md:gap-4 items-start md:items-end">
						<p className="">Ready to become a talent at Nova?</p>
						<a target="_blank" rel="noopener noreferrer" href="https://talent.nova.et/">
							<Button secondary>Join as a talent</Button>
						</a>
						<div className="flex gap-5 text-white/85">
							<FaFacebook className="text-xl md:text-2xl hover:scale-105 transition-all duration-100 hover:text-white"/>
							<FaTwitterSquare className="text-xl md:text-2xl hover:scale-105 transition-all duration-100 hover:text-white"/>
							<FaInstagram className="text-xl md:text-2xl hover:scale-105 transition-all duration-100 hover:text-white"/>
						</div>
					</div>
				</div>
				<p className="pt-5 md:text-sm text-base flex justify-center  font-thin font-beVietnamPro  mx-auto text-white/85">
					&copy; 2024 All rights Reserved{" "} </p>
			</div>
		</div>
	);
};

export default Footer;
