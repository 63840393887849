import React, { useLayoutEffect, useState } from "react";
import Login from "../components/auth/Login";
import ForgotPassword from "../components/auth/ForgotPassword";
const AuthPage = () => {
  const [showLoginPage, setShowLoginPage] = useState(true);

  const handleForgotPasswordClick = () => {
    setShowLoginPage(false);
  };

  const handleLoginClick = () => {
    setShowLoginPage(true);
  };
  useLayoutEffect(() => {
    document.title = "Login | Nova";
  }, []);
  return (
    <div className="py-32 bg-primaryBlack-dark  font-beVietnamPro">
      <div className="w-10/12 sm:w-8/12 md:w-1/2 lg:w-1/3 mx-auto">
        {showLoginPage ? (
          <div>
            <div className="flex flex-col items-center">
              <h1 className="pb-4 md:pb-7 mx-auto flex justify-center text-2xl md:text-3xl lg:text-4xl tracking-wide font-DM text-white">
                Login
              </h1>
            </div>

            <Login onForgotPasswordClick={handleForgotPasswordClick} />
          </div>
        ) : (
          <div className="">
            <div className="flex flex-col items-center gap-1 md:gap-3 pb-4 md:pb-7">
              <h1 className="mx-auto flex justify-center text-2xl md:text-3xl lg:text-4xl font-DM text-white">
                Forgot Password
              </h1>

              <p className="text-center text-sm md:text-base font-roboto text-white/60 font-light">
                Enter your email address and we will send you an OTP your email
                to reset your password.
              </p>
            </div>

            <ForgotPassword onLoginClick={handleLoginClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthPage;
