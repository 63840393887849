import React from "react";
import Navigation from "./components/layout/Navigation";
import Footer from "./components/layout/Footer";
import Home from "./pages/Home";
import AuthPage from "./pages/AuthPage";
import Verify from "./pages/Verify";
import Signup from "./pages/Signup";
import Categories from "./pages/Categories";
import CategoriesDetail from "./pages/CategoriesDetail";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import VerifyAndReset from "./pages/VerifyAndReset";
import Profile from "./pages/Profile";
import Message from "./pages/Message";
import Request from "./pages/Request";
import RequestVideo from "./pages/RequestVideo";
const App = () => {
  const Layout = () => {
    return (
      <div className="">
        <Navigation />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/login",
          element: <AuthPage />,
        },
        {
          path: "/register",
          element: <Signup />,
        },
        {
          path: "/verify",
          element: <Verify />,
        },
        {
          path: "/verify-and-reset",
          element: <VerifyAndReset />,
        },
        {
          path: "/categories",
          element: <Categories />,
        },
        {
          path: "/categories/:id",
          element: <CategoriesDetail />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/message",
          element: <Message />,
        },
        {
          path: "/request",
          element: <Request />,
        },
        {
          path: "/request-video",
          element: <RequestVideo />,
        },
      ],
    },
  ]);
  return (
    <div className="">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
