import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import DropDown from "../components/common/InputDropDown";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../components/common/Input";
import Textarea from "../components/common/Textarea";
import Button from "../components/common/Button";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Loading from "../components/common/Loading";
const RequestVideo = () => {
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("token");

  const navigate = useNavigate();
  const location = useLocation();
  const [recipientType, setRecipientType] = useState("");
  const [requestType, setRequestType] = useState("");
  const name = location.state.name;
  const talentId = parseInt(location.state.id);
  useEffect(() => {
    if (location.state === null) {
      navigate("/login");
    }
  }, [navigate, location.state]);
  const handleSubmit = async (values) => {
    const requestVideo = {
      talentId,
      recipientName: values.recipientName,
      recipientType: values.recipientType,
      requestDetails: values.requestDetails,
      paymentStatus: "pending",
      requestType: values.requestType,
    };
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_USER_PROFILE_URL}/make-request`,
        requestVideo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const message = response.data.message;
      toast.success(message);
      setLoading(false);
      setTimeout(() => {
        navigate("/categories");
      }, 3000);
    } catch (error) {
      setLoading(false);
      const message = error.message;
      toast.error(message);
    }
  };
  // Validation schema using Yup
  const Schema = Yup.object().shape({
    recipientName: Yup.string().required("Recipient Name is required"),
    recipientType: Yup.string().required("Recipient Type is required"),
    requestType: Yup.string().required("Request Type is required"),
    requestDetails: Yup.string().required("Request details is required"),
  });

  // Formik hook for handling form state, validation, and submission
  const formik = useFormik({
    initialValues: {
      recipientName: "",
      recipientType: recipientType,
      requestDetails: "",
      paymentStatus: "pending",
      requestType: requestType,
    },
    validationSchema: Schema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="bg-primaryBlack-dark font-beVietnamPro py-32 text-white">
      <div className="w-10/12 sm:w-8/12 md:w-1/2 lg:w-6/12 mx-auto">
        <h1 className="pb-4 md:pb-7 mx-auto flex justify-center text-lg text-center md:text-xl lg:text-2xl tracking-wide font-semibold text-white">
          Request Video From {name}
        </h1>
        <Toaster />
        {loading ? (
          <Loading />
        ) : (
          <form onSubmit={formik.handleSubmit} className="flex  flex-col gap-6">
            <div className="flex flex-col gap-1 md:gap-4">
              <DropDown
                header="Step 1: Type of video request"
                label="Request Type"
                data={[
                  { name: "Birthday" },
                  { name: "Motivation & Inspiration" },
                  { name: "Warm Wishes & Encouragement" },
                  { name: "Special Song Dedications" },
                  { name: "Motivation & Inspiration" },
                  { name: "Life Advice & Tips" },
                ]}
                error={formik.touched.requestType && formik.errors.requestType}
                onChange={(selectedType) => {
                  setRequestType(selectedType);
                  formik.setFieldValue("requestType", selectedType);
                }}
                onBlur={formik.handleBlur}
              />
              <DropDown
                header="Step 2: Who is this video for?"
                label="Video for"
                data={[{ name: "Myself" }, { name: "Someone else" }]}
                error={
                  formik.touched.recipientType && formik.errors.recipientType
                }
                onChange={(selectedType) => {
                  setRecipientType(selectedType);
                  formik.setFieldValue("recipientType", selectedType); // Update formik value
                }}
                onBlur={formik.handleBlur}
              />

              <Input
                type="text"
                label="To (First Name):"
                placeHolder="First Name"
                require={true}
                name="recipientName"
                value={formik.values.recipientName}
                error={
                  formik.touched.recipientName && formik.errors.recipientName
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <Textarea
                type="text"
                label={`What is something you want ${name} to know about you `}
                placeHolder="I have been A fan......."
                require={true}
                name="requestDetails"
                value={formik.values.requestDetails}
                error={
                  formik.touched.requestDetails && formik.errors.requestDetails
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </div>
            <Button type="submit" tertiary>
              Submit
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default RequestVideo;
