import React, {useEffect, useRef, useState} from "react";

import Profile from "../../assets/images/profile.svg";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import useFetchProfile from "../../hooks/useFetchProfile";

const NavDropDown = ({data}) => { // Retrie ves the authentication token from the session storage
	const token = sessionStorage.getItem("token");

	// Fetches the user's profile data using the custom hook
	const {profileData} = useFetchProfile(token);
	const profile_image = `${
		profileData ?. profile_image
	}` || "";

	const [avatar, setAvatar] = useState(null);

	// Updates the selectedImage state when profile_picture changes
	useEffect(() => {
		const imageUrl = ` ${
			profile_image === "null" ? Profile : profile_image ?. replace("/home/novaet/", "https://")
		}`;

		setAvatar(imageUrl);
	}, [profile_image]);
	const [showList, setShowList] = useState(false);
	const navigate = useNavigate();
	const divEl = useRef();

	const handleLogout = () => { // Clear token from session storage
		sessionStorage.removeItem("token");

		// Clear token from Axios headers
		delete axios.defaults.headers.common["Authorization"];
		navigate("/login");
		setShowList(false);
	};

	useEffect(() => {
		const handler = (event) => {
			if (! divEl.current) {
				return;
			}
			if (! divEl.current.contains(event.target)) {
				setShowList(false);
			}
		};
		document.addEventListener("click", handler, true);
		return() => {
			document.removeEventListener("click", handler, true);
		};
	});
	return (
		<div ref={divEl}
			className="relative">
			<div className="flex gap-2 text-white items-end justify-end ">
				<img src={avatar}
					onClick={
						() => setShowList(!showList)
					}
					alt="ProfilePic"
					className="cursor-pointer rounded-full w-9 h-9 md:w-10 md:h-10 object-cover"/> {
				showList ? (
					<div className="">
						<IoIosArrowUp onClick={
								() => setShowList(!showList)
							}
							className="text-2xl cursor-pointer"/>
					</div>
				) : (
					<div className="">
						<IoIosArrowDown onClick={
								() => setShowList(!showList)
							}
							className="text-2xl cursor-pointer"/>
					</div>
				)
			} </div>
			<div id="dropdown"
				className={
					`z-[50] w-[12rem]
           absolute top-full right-0 bg-primaryBlack-medium mt-1 rounded shadow py-3   ${
						showList ? " visible opacity-100" : "invisible opacity-0"
					}`
			}>
				<ul className=" text-sm text-center  max-h-[14rem] overflow-y-auto" aria-labelledby="dropdownDefaultButton">
					{
					data.map(({
						name
					}, idx) => {
						if (name !== "logout") {
							return (
								<Link to={
										`/${name}`
									}
									key={idx}>
									<li>
										<p onClick={
												() => setShowList(false)
											}
											className="capitalize py-2 text-sm md:text-base relative cursor-pointer z-10 transition-all hover:bg-white/25 text-white">
											{name} </p>
									</li>
								</Link>
							);
						} else {
							return (
								<div key={idx}>
									<p onClick={handleLogout}
										className="capitalize py-2 text-sm md:text-base relative cursor-pointer z-10 transition-all hover:bg-white/25 text-white">
										{name} </p>
								</div>
							);
						}
					})
				} </ul>
			</div>
		</div>
	);
};

export default NavDropDown;
