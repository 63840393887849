import React from "react";
import { howItWorks } from "../../data";
import { motion } from "framer-motion";
const HowItWorks = () => {
  const rightCardAnimate = {
    offScreen: {
      x: 100,
      opacity: 0,
    },
    onScreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };

  const leftCardAnimate = {
    offScreen: {
      x: -100,
      opacity: 0,
    },
    onScreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };

  const BottomCardAnimate = {
    offScreen: {
      y: 100,
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };
  return (
    <div className="bg-primaryBlack-dark py-12 md:py-20">
      <div className="w-11/12 max-w-[1440px] mx-auto">
        <div className="flex md:gap-2 flex-col items-start">
          <h1 className="text-xl md:text-xl text-center md:text-left font-DM xl:text-3xl text-white">
            Why Choose Us
          </h1>
          <p className="text-white/80 text-center md:text-left font-roboto text-sm md:text-base tracking-wide">
            Get a personal video from your favorite celebrity.
          </p>
        </div>
        <motion.div
          transition={{ staggerChildren: 0.1 }}
          initial={"offScreen"}
          whileInView={"onScreen"}
          viewport={{ once: true, amount: 0.2 }}
          className="pt-6 md:pt-7 lg:w-full grid gird-cols-1 sm:w-9/12  lg:grid-cols-3 gap-5 "
        >
          {howItWorks.map((item, index) => {
            return (
              <motion.div
                variants={
                  index === 0
                    ? leftCardAnimate
                    : index === 1
                    ? BottomCardAnimate
                    : rightCardAnimate
                }
                key={index}
                className="bg-primaryBlack-medium/85 rounded-xl  p-6 md:p-7 transition-all duration-100 hover:bg-primaryBlack-medium"
              >
                <div className="flex flex-col gap-4">
                  <div className="text-white/90 text-3xl">{item.icon}</div>
                  <div className="flex flex-col gap-2">
                    <h1 className="text-base md:text-lg font-DM lg:text-xl text-white">
                      {item.title}
                    </h1>
                    <p className="text-white/65 font-beVietnamPro  text-xs sm:text-sm">
                      {item.desc}
                    </p>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    </div>
  );
};

export default HowItWorks;
