import axios from "axios";
import { useState } from "react";

const useSearch = (query) => {
  const [searching, setSearching] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [message, setMessage] = useState();
  const handleSearch = async () => {
    setSearching(true);
    try {
      // Getting response from the API
      const response = await axios.get(
        `${process.env.REACT_APP_API_CAT_URL}/talents-search?query=${query}`
      );
      console.log(response);
      const message = response.data.message;

      setMessage(message);

      setSearchResult(response.data);
    } catch (error) {
      const message = error.message;
      console.log(error);
      setMessage(message);
    }
  };

  return { searching, searchResult, message, handleSearch };
};
export default useSearch;
