import React from "react";

const Button = ({
	primary,
	secondary,
	tertiary,
	children,
	type,
	handleClick,
	small
}) => { // Define the base class for all button styles
	let buttonClasses = "rounded-full transition-all duration-500 hover:shadow-xl";

	if (primary) { // Add classes for primary button style
		buttonClasses += " text-accentBlue-dark py-3.5 px-6 md:px-8 lg:py-4 lg:px-12 font-semibold bg-white/95 rounded-full hover:bg-white text-sm md:text-base lg:text-xl";
	} else if (secondary) { // Add classes for secondary button style
		buttonClasses += " border-2 text-white/65 py-2 px-3 md:py-2 md:px-5 lg:py-2 lg:px-6 text-sm md:text-base font-normal border-white/65 hover:border-white hover:text-white";
	} else if (tertiary) { // Add classes for tertiary button style
		buttonClasses += `${
			small ? " py-2.5 px-10 md:px-8 capitalize lg:py-3.5 lg:px-12 text-sm md:text-base" : " py-2.5 px-10 font-semibold uppercase md:px-10 lg:py-4 lg:px-16 text-base lg:text-xl"
		} bg-accentPurple-dark/85 text-white/85 hover:bg-accentPurple-dark`;
	}

	return (
		<button type={
				type || "button"
			}
			className={buttonClasses}
			onClick={handleClick}>
			{children} </button>
	);
};

export default Button;
