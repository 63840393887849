import React, { useLayoutEffect } from "react";
import Hero from "../components/layout/Hero";
import Banner from "../components/layout/Banner";
import HowItWorks from "../components/layout/HowItWorks";
import Explanation from "../components/layout/Explanation";
const Home = () => {
  useLayoutEffect(() => {
    document.title = "Nova";
  }, []);
  return (
    <div className="overflow-hidden">
      <Hero />
      <Explanation />
      <Banner />
      <HowItWorks />
    </div>
  );
};

export default Home;
