import React from "react";
import { Link } from "react-router-dom";
import Image from "../../assets/images/image.jpg";

const SearchResult = ({ result, showResult, navOpen }) => {
  const handleClick = () => {
    showResult(false);
    navOpen(false);
  };
  return (
    <div className="fixed text-white  z-[100] bg-primaryBlack-medium font-beVietnamPro py-10 h-full w-full top-100">
      <div className="flex flex-col  gap-5 ">
        {result.length === 0 ? (
          <p className="capitalize px-5 text-white text-sm">
            No result found!!!
          </p>
        ) : (
          result.map((item, index) => {
            const { first_name, last_name, profile_picture, skills } = item;
            const baseUrl = "https:/";
            const relativePath = profile_picture?.replace("home/novaet/", "");
            const imageUrl =
              profile_picture === null ? Image : baseUrl + relativePath;
            return (
              <Link
                to={`/categories/${item.talent_id}`}
                onClick={handleClick}
                key={index}
                className="hover:bg-white/15 py-1 transition-all duration-300"
              >
                <div className="flex px-5 gap-3 items-center">
                  <img
                    src={imageUrl}
                    alt=""
                    className="object-cover w-16 h-16 rounded-full"
                  />
                  <div className="flex flex-col gap-1">
                    <p className="capitalize ">
                      {first_name} {last_name}
                    </p>
                    <p className="capitalize text-white/85 text-sm">{skills}</p>
                  </div>
                </div>
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
};

export default SearchResult;
