import React, {useEffect} from "react";
import ReactDOM from "react-dom";

const Modal = ({onClose, children, actionBar}) => { // Add "overflow" class to body when the modal is opened
	useEffect(() => {
		document.body.classList.add("overflow");

		// Clean up function to remove "overflow" class when the modal is closed
		return() => {
			document.body.classList.remove("overflow");
		};
	}, []);

	// Render the modal using ReactDOM.createPortal
	return ReactDOM.createPortal (
		<div> {/* Overlay background */}
			<div onClick={onClose}
				className="fixed bg-gradient-to-r from-accentPurple-dark/95 to-accentBlue-dark/95 top-0 left-0 right-0 bottom-0 z-40"></div>
			{/* Modal content */}
			<div className="fixed bg-primaryBlack-dark px-5 py-3 w-[18rem] sm:w-[30rem] md:w-[40rem] h-[33rem] top-[50%] left-[50%] right-[40%] bottom-[50%] -translate-y-1/2 -translate-x-1/2 z-50 rounded-lg">
				{/* Action bar */}
				<div className="flex justify-end">
					{actionBar}</div>
				{/* Modal body */}
				<div className="flex justify-center mx-auto">
					<div className="w-11/12 md:w-10/12 lg:w-9/12">
						{children}</div>
				</div>
			</div>
		</div>,
		document.querySelector(".modal-container") // Render the modal inside the ".modal-container" element
	);
};

export default Modal;
