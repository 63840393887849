import React, {useEffect, useState} from "react";
import Image from "../../assets/images/image.jpg";
import {FaStar} from "react-icons/fa";
const Card = ({items}) => {
	const {
		first_name,
		last_name,
		skills,
		profile_picture,
		rate
	} = items;
	const rating = 4.07;
	const ratingQuantity = 400;
	const [avatar, setAvatar] = useState(null);

	// Updates the selectedImage state when profile_picture changes
	useEffect(() => {
		const imageUrl = ` ${
			profile_picture === null ? Image : profile_picture ?. replace("/home/novaet/", "https://")
		}`;
		setAvatar(imageUrl);
	}, [profile_picture]);
	return (
		<div className="group flex flex-col bg-primaryBlack-medium rounded-lg
								      font-beVietnamPro">
			<div className="rounded-t-lg overflow-hidden">
				<img src={avatar}
					alt={
						`${first_name} ${last_name}`
					}
					className="object-cover w-full h-[16rem] group-hover:scale-105 transition-all duration-300"/>
			</div>
			<div className="px-5 flex py-5 flex-col gap-1">
				<p className="text-white/70 uppercase tracking-widest text-xs  font-light ">
					{skills} </p>
				<p className="text-sm md:text-base text-white font-light">
					{first_name}
					{last_name} </p>
				<div className="flex gap-1 justify-between">
					<div className="flex gap-1 font-light items-center">
						<FaStar className="text-yellow-500"/>
						<div className="text-sm md:text-base text-white">
							{rating}
							<span className="text-white/60">
								({ratingQuantity})</span>
						</div>
					</div>
					{
					rate !== null && (
						<div className="text-sm md:text-base text-white/95 font-light">
							{rate}ETB
						</div>
					)
				} </div>
			</div>
		</div>
	);
};

export default Card;
