import axios from "axios";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

const useUpdateProfile = (token) => {
  const [updating, setUpdating] = useState(false);
  const handleSubmit = async (values) => {
    const fileInput = document.getElementById("picture").files[0]; // Get the selected file

    var data = new FormData();
    data.append("email", values.email);
    data.append("bio", values.bio);
    data.append("phone", values.phone);
    if (fileInput) {
      data.append("profilePicture", fileInput);
    }
    try {
      // Getting response from the API
      setUpdating(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_USER_PROFILE_URL}/update-profile`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      const message = response.data.message;
      toast.success(message);
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      const message = error.message;
      toast.error(message);
    }
  };

  return { updating, toast, handleSubmit, Toaster };
};
export default useUpdateProfile;
