import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../components/common/Loading";
import Image from "../assets/images/image.jpg";
import Button from "../components/common/Button";
import Error from "../components/common/Error";
import useFetchTalent from "../hooks/useFetchTalent";

const CategoriesDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { celebrities, isLoading, error } = useFetchTalent(id);

  const [avatar, setAvatar] = useState(null);
  const [avatarUrls, setAvatarUrls] = useState([]);
  const [showFullText, setShowFullText] = useState(false);

  const handleRequestVideo = () => {
    const token = sessionStorage.getItem("token");
    if (token === null) {
      navigate("/login");
    } else {
      navigate("/request-video", {
        state: { name: userName, id: id },
      });
    }
  };
  const {
    first_name,
    last_name,
    skills,
    bio,
    rate,
    profile_picture,
    pictures,
  } = celebrities;
  const userName = `${first_name} ${last_name}`;
  // Updates the avatarUrls state when pictures change
  useEffect(() => {
    const updatedUrls = pictures?.map((picture) =>
      picture.replace("/home/novaet/", "https://")
    );
    setAvatarUrls(updatedUrls);
  }, [pictures]);
  // Updates the selectedImage state when profile_picture changes
  useEffect(() => {
    const imageUrl = ` ${
      profile_picture === null
        ? Image
        : profile_picture?.replace("/home/novaet/", "https://")
    }`;
    setAvatar(imageUrl);
  }, [profile_picture]);

  return (
    <div className="py-32 text-white bg-primaryBlack-dark font-beVietnamPro ">
      {isLoading ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <div className="w-11/12 max-w-[1440px] mx-auto relative">
          <div className=" text-sm md:text-base flex gap-2 md:gap-3 font-light">
            <Link
              to="/"
              className="text-white/80 hover:text-accentPurple-dark transition-all duration-300"
            >
              Home
            </Link>
            <span className="">&gt;</span>
            <Link
              to="/categories"
              className="text-white/80 hover:text-accentPurple-dark transition-all duration-300"
            >
              Explore
            </Link>
            <span className="">&gt;</span>
            <Link
              to={`/categories/${id}`}
              className="text-accentPurple-dark/85 hover:text-accentPurple-dark transition-all duration-300"
            >
              {first_name} {last_name}
            </Link>
            <span className=""></span>
          </div>

          <div className="flex flex-col md:flex-row justify-between gap-20 items-start mt-6 md:mt-10">
            <div className="flex w-full md:w-1/2 flex-col md:flex-row justify-between gap-10 items-start ">
              <div className="flex flex-col  gap-4 md:gap-5">
                <div className="flex flex-col gap-2">
                  <img
                    src={avatar}
                    alt={first_name}
                    className="rounded-md w-48  md:w-64"
                  />

                  <div className="flex flex-col gap-1">
                    <h4 className="text-xs md:text-sm lg:text-base uppercase tracking-wide text-white/75 font-light">
                      {skills}{" "}
                    </h4>
                    <h1 className="text-lg font-DM capitalize md:text-xl lg:text-3xl">
                      {userName}
                    </h1>
                  </div>
                  <div className="flex flex-col gap-1">
                    {bio !== null && (
                      <div className="flex flex-col gap-1.5 items-start">
                        <p className="w-10/12 md:w-9/12 text-sm md:text-base font-thin text-white/75">
                          {showFullText ? bio : ` ${bio.slice(0, 200)}.... `}{" "}
                        </p>
                        {bio.length > 200 && (
                          <button
                            className="font-light underline text-sm md:text-base  text-white/80 transition-all duration-300 hover:text-white"
                            onClick={() => setShowFullText(!showFullText)}
                          >
                            {showFullText ? "Read Less" : "Read More"}{" "}
                          </button>
                        )}{" "}
                      </div>
                    )}
                    {rate !== null && (
                      <div className="text-sm md:text-base text-white/95 font-light">
                        Rate: {rate}
                        ETB
                      </div>
                    )}{" "}
                  </div>
                </div>
                <Button tertiary small handleClick={handleRequestVideo}>
                  Request a personal video{" "}
                </Button>
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 items-center justify-start">
                {avatarUrls?.map((item, index) => {
                  return (
                    <img
                      key={index}
                      src={item}
                      alt={first_name}
                      className="object-cover w-full h-[12rem] md:h-[10rem] rounded"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}{" "}
    </div>
  );
};
export default CategoriesDetail;
