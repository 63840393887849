import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import Input from "../components/common/Input";
import Textarea from "../components/common/Textarea";
import Error from "../components/common/Error";
import Button from "../components/common/Button";
import Loading from "../components/common/Loading";
import useFetchProfile from "../hooks/useFetchProfile";
import useUpdateProfile from "../hooks/useUpdateProfile";
import Image from "../../src/assets/images/profile.svg";
import ModalParent from "../components/common/ModalParent";
const Profile = () => {
  // Retrieves the authentication token from the session storage
  const token = sessionStorage.getItem("token");

  // Fetches the user's profile data using the custom hook
  const { profileData, error, loading } = useFetchProfile(token);

  // Handles profile update using the custom hook
  const { handleSubmit, Toaster, updating } = useUpdateProfile(token);

  // State variable to store the selected profile picture
  const [selectedImage, setSelectedImage] = useState(null);

  // Extracting profile data and setting default values
  const userName = `${profileData?.first_name} ${profileData?.last_name}` || "";
  const email = `${profileData?.email}` || "";
  const phone = `${profileData?.phone} ` || "";
  const rate = `${profileData?.rate} ` || "";
  const bio = `${profileData?.bio}` || "";
  const profile_image = `${profileData?.profile_image}` || "";

  // Handles image selection for the profile picture
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const imageData = e.target.result;

      setSelectedImage(imageData);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Updates the selectedImage state when profile_picture changes
  useEffect(() => {
    const imageUrl = ` ${
      profile_image === "null"
        ? Image
        : profile_image?.replace("/home/novaet/", "https://")
    }`;
    setSelectedImage(imageUrl);
  }, [profile_image]);

  // Validation schema using Yup
  const Schema = Yup.object().shape({
    name: Yup.string().required("First name is required"),
    phone: Yup.string()
      .matches(/^\+?[0-9]\d{0,14}$/, "Invalid phone number")
      .required("Phone is required"),
    bio: Yup.string().required("Bio is required").min(3),
  });
  // Formik hook for handling form state, validation, and submission
  const formik = useFormik({
    initialValues: {
      name: userName,
      email: email,
      phone: phone,
      bio: bio,
      rate: rate,
    },
    validationSchema: Schema,
    onSubmit: handleSubmit,
  });

  // Populating form values with fetched profileData
  useEffect(() => {
    if (profileData) {
      formik.setFieldValue("name", userName);
      formik.setFieldValue("email", email.trim());
      formik.setFieldValue(
        "bio",
        bio.trim() === "null" || bio.trim() === "undefined" ? "" : bio.trim()
      );

      formik.setFieldValue(
        "phone",
        phone.trim() === "null" || phone.trim() === "undefined"
          ? ""
          : phone.trim()
      );
    }
  }, [profileData]);
  // console.log(selectedImage);
  return (
    <div className="bg-primaryBlack-dark font-beVietnamPro py-36 text-white">
      <Toaster />
      {/* Conditional rendering based on loading, error, or success state  */}
      {loading || updating ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <div className="w-10/12   gap-7 sm:w-8/12 md:w-1/2 lg:w-1/3 mx-auto">
          <div className="flex flex-col gap-2 items-center">
            <div className="flex items-end mx-auto">
              <img
                src={selectedImage === null ? Image : selectedImage}
                alt="ProfilePic"
                className="rounded-full w-24 h-24 md:w-32 md:h-32 lg:w-44 lg:h-44 object-cover"
              />

              <label htmlFor="picture">
                <FaEdit className="text-lg lg:text-xl cursor-pointer hover:text-accentPurple-dark transition-all duration-300" />
              </label>
              <input
                type="file"
                id="picture"
                name="picture"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
            </div>
            <p className="">{userName}</p>
          </div>
          <div className="flex justify-center py-5">
            <ModalParent />
          </div>

          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
            <div className="flex flex-col gap-1 md:gap-3 ">
              <Input
                type="email"
                label="Email"
                placeHolder="Email"
                require={true}
                disabled
                name="email"
                value={formik.values.email}
                error={formik.touched.email && formik.errors.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <Textarea
                type="text"
                label="Bio"
                placeHolder="Bio"
                require={true}
                name="bio"
                value={formik.values.bio}
                error={formik.touched.bio && formik.errors.bio}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <Input
                type="tel"
                label="Phone"
                placeHolder="Phone"
                require={true}
                name="phone"
                value={formik.values.phone}
                error={formik.touched.phone && formik.errors.phone}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />

              <Button
                type="submit"
                tertiary
                handleClick={() => {
                  console.log(loading);
                }}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Profile;
