import {MdOutlineAccessTimeFilled,MdPeople} from "react-icons/md"
import { LuUpload } from "react-icons/lu"
import { IoSparklesSharp } from "react-icons/io5";
import { BiSolidVideoRecording } from "react-icons/bi";
import { FaCircleCheck } from "react-icons/fa6";

export const howItWorks = [
  {
    "icon": <MdPeople />,
    "title": "Authentic Connections",
    "desc":"Make real connections with Ethiopian stars, leaving you feeling inspired and happy."
  },
  {
    "icon": <MdOutlineAccessTimeFilled />,
    "title": "Exclusive Access",
    "desc":"Become a VIP fan! Get closer to your favorite celebs than ever before with personalized messages."
  },
  {
    "icon": <IoSparklesSharp />,
    "title":"Memorable Moments",
    "desc": "Give the gift (or keep it for yourself!) of a personalized video shoutout - a timeless treasure you'll never forget.",
  }
]
export const talent = [
  {
 
    "icon": <FaCircleCheck />,
    "title": "Get verified",
    "desc":"Download the nova app to complete account set up. We’ll verify your identity and then you’ll be ready to shine."
  },   
  
  {
 
    "icon": <LuUpload />,
    "title": "Share your profile",
    "desc":"Browse thousands of stars offering personalized videos."
  },   
  
  {
 
    "icon": <BiSolidVideoRecording />,
    "title": "Record and earn",
    "desc":"Fulfill fan requests on your schedule and cash out daily."
  },   
  
  {
 
    "icon": <IoSparklesSharp />,
    "title": "Feel the magic",
    "desc":"Deepen connections with fans, grow your brand, and watch the magic unfold."
  },   
  
]
