import React, {useState} from "react";
import Modal from "./Modal";
import ChangePassword from "./ChangePassword";

const ModalParent = () => { // State variable to control the visibility of the modal
	const [showModal, setShowModal] = useState(false);

	// Event handler for the button click to toggle the modal visibility
	const handleClick = () => {
		setShowModal(!showModal);
	};

	// Event handler for closing the modal
	const handleClose = () => {
		setShowModal(false);
	};

	// Action bar component for the modal
	const actionBar = (
		<div>
			<button onClick={handleClose}
				className="font-semibold text-white font-beVietnamPro hover:text-red-400 transition-all duration-200">
				x
			</button>
		</div>
	);

	// Modal content component
	const modal = (
		<Modal onClose={handleClose}
			actionBar={actionBar}>
			<ChangePassword showModal={setShowModal}/>
		</Modal>
	);

	return (
		<div> {/* Button to trigger the modal */}
			<button onClick={handleClick}
				className="text-accentPurple-medium hover:text-accentPurple-dark transition-all duration-200">
				Change Password
			</button>

			{/* Render the modal when showModal is true */}
			{
			showModal && modal
		} </div>
	);
};

export default ModalParent;
