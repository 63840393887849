import React from "react";
import CardPic from "../../assets/images/maxresdefault.jpg";
import Button from "../common/Button";
import { Link } from "react-router-dom";
const Card = () => {
  return (
    <div className="bg-accentBlue-dark ">
      <div className="">
        <div className="flex flex-col md:flex-row  gap-5 items-center justify-between">
          <div className="w-11/12 mx-auto md:pl-12 xl:pl-16 pt-10 md:py-20 flex gap-3 md:gap-5 flex-col items-start">
            <div className="flex flex-col gap-1">
              <h1 className="text-2xl md:text-3xl font-DM xl:text-4xl text-white">
                Limited Time Offer!
              </h1>
              <p className="text-white/90 font-beVietnamPro text-sm md:text-lg">
                Get a FREE video message from your favorite Ethiopian celeb!
              </p>
            </div>
            <Link to="/categories">
              <Button primary>Browse a star</Button>
            </Link>
          </div>
          <div className=" ">
            <img src={CardPic} alt="" className="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
