import React, {useState, useEffect, useRef} from "react";

const DropDown = ({
	label,
	id,
	data,
	onChange,
	error
}) => {
	const [showList, setShowList] = useState(false);
	const [selected, setSelected] = useState(label);

	// Ref for the dropdown element
	const dropdownRef = useRef(null);

	// Handle change event when a dropdown item is selected
	const handleChange = (name) => {
		setSelected(name);
		onChange(name);
	};

	// Handle click outside the dropdown to close it
	const handleClickOutside = (event) => {
		if (dropdownRef.current && ! dropdownRef.current.contains(event.target)) {
			setShowList(false);
		}
	};

	// Add event listeners for click and touch events to handle outside clicks
	useEffect(() => {
		const handleClick = (event) => {
			if (!window.matchMedia("(pointer: coarse)").matches && event.type === "click") {
				return;
			}
			handleClickOutside(event);
		};

		document.addEventListener("click", handleClick);
		document.addEventListener("touchend", handleClick);

		return() => {
			document.removeEventListener("click", handleClick);
			document.removeEventListener("touchend", handleClick);
		};
	}, []);

	return (
		<>
			<button onClick={
					() => setShowList((prev) => !prev)
				}
				onBlur={
					() => setShowList(false)
				}
				onTouchStart={
					() => setShowList((prev) => !prev)
				}
				id={id}
				data-dropdown-toggle="dropdown"
				className="w-[10rem] h-full text-white bg-primaryBlack-medium border-[.7px] rounded-full px-4 py-2 focus:outline-none text-sm text-center flex items-center justify-between relative gap-4 capitalize group"
				type="button">
				<span className="text-sm md:text-base">
					{selected}</span>
				<svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
				</svg>
				<div ref={dropdownRef}
					id="dropdown"
					className={
						`z-[50] w-full absolute top-full left-0 bg-primaryBlack-medium mt-1 rounded shadow p-3 ${
							showList ? "visible opacity-100" : "invisible opacity-0"
						}`
				}>
					<ul className="text-sm text-center max-h-[17rem] overflow-y-auto" aria-labelledby="dropdownDefaultButton">
						{
						data.map(({
							name
						}, idx) => (
							<li key={idx}
								onClick={
									() => handleChange(name)
							}>
								<p className="capitalize border-b border-b-darkGray py-1.5 text-sm md:text-base relative cursor-pointer z-10 transition-all bg-[-100%] duration-500 bg-[length:200%_100%] text-transparent bg-text-gradient bg-clip-text hover:bg-[0%]">
									{name} </p>
							</li>
						))
					} </ul>
				</div>
				{
				error && (
					<p className="text-red-500 text-[12px] capitalize absolute top-full">
						This is a required field
					</p>
				)
			} </button>
		</>
	);
};

export default DropDown;
