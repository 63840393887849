import * as Yup from "yup";
import axios from "axios";
import {useFormik} from "formik";
import {useState} from "react";
import toast, {Toaster} from "react-hot-toast";
import {Link, useNavigate} from "react-router-dom";
import Input from "../common/Input";
import Button from "../common/Button";
import Loading from "../common/Loading";

const Login = ({onForgotPasswordClick}) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	// Function to handle form submission
	const handleSubmit = async (values) => {
		try { // Getting response from the API
			setLoading(true);
			const response = await axios.post(`${
				process.env.REACT_APP_API_URL
			}/login`, values);
			const token = response.data.token;

			// Setting sessionStorage with the token received from the API
			sessionStorage.setItem("token", token);
			const message = response.data.token && "You have successfully logged in";
			toast.success(message);

			navigate("/");
		} catch (error) {
			const message = error.message;
			setLoading(false);
			toast.error(message);
		}
	};

	// Validation schema using Yup
	const Schema = Yup.object().shape({
		email: Yup.string().matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, "Invalid email format").required("Enter a value for this field."),
		password: Yup.string().min(8, "Your password must contain at least 8 characters.").required("Password is required")
	});

	// Initialize useFormik hook with formik configuration
	const formik = useFormik({
		initialValues: {
			email: "",
			password: ""
		},
		validationSchema: Schema,
		onSubmit: handleSubmit
	});

	// Event handler for input change
	const handleInputChange = (e) => {
		const {name, value} = e.target;
		formik.handleChange(e);
		formik.setFieldValue(name, value);
	};

	return (<div className="relative">
		<Toaster/> {loading ? (
						        <Loading /> // Show loading component if loading state is true
						      ) : (
						        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
						          <div className="flex flex-col gap-1 md:gap-3">
						            {/* Input component for email */}
						            <Input
						              type="email"
						              label="Email"
						              placeHolder="example@gmail.com"
						              require={true}
						              name="email"
						              value={formik.values.email}
						              error={formik.touched.email && formik.errors.email}
						              onBlur={formik.handleBlur}
						              onChange={handleInputChange}
						            />
						
						            {/* Input component for password */}
						            <Input
						              type="password"
						              label="Password"
						              placeHolder="Password"
						              require={true}
						              name="password"
						              value={formik.values.password}
						              error={formik.touched.password && formik.errors.password}
						              onBlur={formik.handleBlur}
						              onChange={handleInputChange}
						            />
						
						            {/* Sign up link */}
						            <div className="font-beVietnamPro text-center justify-center text-sm text-white/50 font-light">
						              If you don't have an account, please{" "}
						              <Link
						                to="/register"
						                className="uppercase font-normal  tracking-wider transition-all duration-500 text-accentBlue-dark hover:text-accentBlue-medium"
						              >
						                {" "}
						                Signup
						              </Link>
						            </div>
						
						            {/* Forgot password link */}
						            <div
						              className=" cursor-pointer font-roboto text-center transition-all duration-500 text-accentBlue-dark hover:text-accentBlue-medium "
						              onClick={onForgotPasswordClick}
						            >
						              Forgot Password
						            </div>
						          </div>
						
						          {/* Submit button */}
						          <Button type="submit" tertiary>
						            Submit
						          </Button>
						        </form>
						      )}
						    </div>
	);
	};

	export default Login;
