import axios from "axios";
import React, { useEffect, useState } from "react";
import RequestCards from "../components/common/RequestCards";
import Error from "../components/common/Error";
import Loading from "../components/common/Loading";
import { FaVideoSlash } from "react-icons/fa";
const Request = () => {
  const token = sessionStorage.getItem("token");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [request, setRequest] = useState([]);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_USER_PROFILE_URL}/pending-requests`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.pendingRequests;
        setRequest(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
    };

    fetchRequests();
  }, []);

  return (
    <div className="bg-primaryBlack-dark  font-beVietnamPro text-white">
      {isLoading ? (
        <div className="py-32">
          <Loading />
        </div>
      ) : error ? (
        <div className="py-32">
          <Error error={error} />
        </div>
      ) : (
        <div className="">
          <div className=" pt-28 md:pt-32 h-[12rem] md:h-[15rem] bg-gradient-to-b from-accentBlue-dark to-accentPurple-dark">
            <h1 className="font-DM text-white w-11/12 mx-auto text-lg md:text-3xl">
              Request
            </h1>
          </div>
          <div className="w-11/12  mx-auto">
            {request.length === 0 ? (
              <div className="font-DM w-11/12 flex flex-col gap-2 text-center py-20 items-center text-xl md:text-2xl justify-center  tracking-wide mx-auto">
                <h1 className="text-white/90 font-DM">
                  You have not made a video requests so far!
                </h1>
                <FaVideoSlash className="text-2xl md:text-3xl lg:text-4xl text-white/85" />
              </div>
            ) : (
              <div className="pb-28">
                <div className="mx-auto mt-10 flex flex-col pb-2 md:pb-4">
                  <h1 className="font-DM flex text-lg md:text-xl tracking-wide text-white">
                    List of requests you have made
                  </h1>
                </div>
                <div className="overflow-x-auto table-scrollbar ">
                  <table className="table-auto w-[760px] md:w-full bg-gradient-to-br from-[#1a0e30ab] to-[#38216479] ">
                    <thead>
                      <tr className="bg-accentPurple-dark/45 text-sm md:text-base text-center text-white">
                        <td className="p-2 md:p-3 w-1/6"> Talent Name</td>
                        <td className="p-2 md:p-3 w-1/6">TalentPic</td>
                        <td className="p-2 md:p-3 w-1/6"> Recipient Type</td>
                        <td className="p-2 md:p-3 w-1/6"> Request Type</td>
                        <td className="p-2 md:p-3 w-1/6"> Rate </td>
                        <td className="p-2 md:p-3 w-1/6"> Payment Status</td>
                      </tr>
                    </thead>
                    <tbody>
                      {request.map((item, index) => (
                        <RequestCards request={item} key={index} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Request;
