import axios from "axios";
import React, { useEffect, useState } from "react";
import MessageCard from "../components/common/MessageCard";
import Loading from "../components/common/Loading";
import Error from "../components/common/Error";
import { MdMessage } from "react-icons/md";
import { BiSolidMessageX } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
const Message = () => {
  const token = sessionStorage.getItem("token");
  const [error, setError] = useState(null);
  const [video, setVideo] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCardMob, setSelectedCardMob] = useState(false);

  const [completedRequest, setCompletedRequest] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [messageDetails, setMessageDetails] = useState(null);

  const fetchAllCelebrities = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_USER_PROFILE_URL}/completed-requests`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.completedRequests;
      setCompletedRequest(data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };
  useEffect(() => {
    fetchAllCelebrities();
  }, []);

  const handleMessageClick = (item, index) => {
    setMessageDetails(item);
    setSelectedCardMob(true);
    setSelectedCard(index);
  };

  useEffect(() => {
    const imageUrl = ` ${
      messageDetails?.video_url === null
        ? ""
        : messageDetails?.video_url?.replace("/home/novaet/", "https://")
    }`;
    setVideo(imageUrl);
  }, [messageDetails]);
  return (
    <div className="bg-primaryBlack-dark font-beVietnamPro">
      {isLoading ? (
        <div className="py-32">
          <Loading />
        </div>
      ) : error ? (
        <div className="py-32">
          <Error error={error} />
        </div>
      ) : (
        <div>
          <div className=" pt-28 md:pt-32 h-[12rem] md:h-[15rem] bg-gradient-to-b from-accentBlue-dark to-accentPurple-dark">
            <h1 className="font-DM text-white w-11/12 mx-auto text-lg md:text-3xl">
              Message
            </h1>
          </div>
          <div className="w-11/12 mx-auto mt-10 pb-32">
            {completedRequest.length === 0 ? (
              <div className="font-DM w-11/12 text-center flex flex-col gap-2 pt-8 md:pt-20 items-center text-xl md:text-2xl justify-center  tracking-wide mx-auto">
                <h1 className="text-white/90">
                  You don't have any message yet!!!
                </h1>
                <BiSolidMessageX className="text-2xl md:text-3xl lg:text-4xl text-white/85" />
              </div>
            ) : (
              <div className="">
                <div className="flex">
                  <div
                    className={`w-full pb-6 rounded-xl md:rounded-r-none md:w-2/5 lg:w-1/3  bg-accentPurple-dark/20  ${
                      selectedCardMob ? "hidden md:block" : ""
                    }`}
                  >
                    {completedRequest.map((item, index) => {
                      return (
                        <MessageCard
                          item={item}
                          key={index}
                          handleClick={() => handleMessageClick(item, index)}
                          isSelected={selectedCard === index}
                        />
                      );
                    })}
                  </div>
                  <div
                    className={` bg-message-pattern rounded-xl md:rounded-l-none w-full md:w-3/5 lg:w-2/3   ${
                      !selectedCardMob ? "hidden md:flex " : "flex"
                    }`}
                  >
                    {messageDetails ? (
                      <div className="p-4 text-xs lg:text-sm  text-white font-light flex flex-col gap-4 items-start md:pl-7 lg:pl-10 md:pt-3 lg:pt-7">
                        <FaArrowLeftLong
                          className="flex md:hidden text-xl cursor-pointer hover:text-accentPurple-medium"
                          onClick={() => {
                            setSelectedCardMob(false);
                          }}
                        />

                        {video && (
                          <video
                            className="w-full h-[25rem] rounded-lg"
                            controls
                            autoPlay
                          >
                            <source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        )}
                        <div className="flex flex-col gap-2 capitalize w-full">
                          <div className="">
                            <span className="text-accentPurple-medium font-semibold">
                              Recipient Name:{" "}
                            </span>
                            {messageDetails.recipient_name}
                          </div>
                          <div className="">
                            <span className="text-accentPurple-medium font-semibold">
                              Request Type:{" "}
                            </span>
                            {messageDetails.recipient_type}
                          </div>
                          <div className="">
                            <span className="text-accentPurple-medium font-semibold">
                              Request Type:{" "}
                            </span>
                            {messageDetails.request_type}
                          </div>
                          <div className="">
                            <span className="text-accentPurple-medium font-semibold">
                              Request Details:{" "}
                            </span>
                            {messageDetails.request_details}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-1 px-2 md:gap-3 items-center text-center text-xl md:text-2xl lg:text-3xl justify-start py-32 tracking-wide  mx-auto">
                        <h1 className="font-DM text-white/90">
                          You can see you your messages here!
                        </h1>
                        <MdMessage className="text-3xl  md:text-4xl  lg:text-5xl text-white/75" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Message;
