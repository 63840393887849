import React from "react";

const Input = ({
  type,
  label,
  value,
  placeHolder,
  error,
  onChange,
  require,
  name,
  readOnly,
  disabled,
  onBlur,
}) => {
  return (
    <div className="z-20 flex font-beVietnamPro flex-col items-start gap-2.5  group ">
      {label ? (
        <label
          htmlFor={name}
          className="text-sm lg:text-base font-light text-white"
        >
          {label} {require && <span className="text-red-300">*</span>}
        </label>
      ) : null}
      <textarea
        disabled={disabled}
        type={type || "text"}
        name={name}
        value={value}
        readOnly={readOnly || false}
        id={name}
        onChange={onChange}
        onBlur={onBlur}
        className={` text-white w-full rounded-lg focus:outline-none focus:ring-0  px-4 md:px-5 py-3 placeholder:font-beVietnamPro bg-primaryBlack-medium ${
          error
            ? "border-red-400/60 border-[.5px] "
            : "border-[.5px]  border-white/10 hover:border-white/25 transition-all duration-500"
        }`}
        placeholder={placeHolder}
      />
      <p
        className={`transition-all duration-300  text-[13px] text-red-400/70 ${
          error ? "translate-y-0 " : "-z-10 opacity-0 -translate-y-1/2"
        }`}
      >
        {error}
      </p>
    </div>
  );
};

export default Input;
