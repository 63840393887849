import * as Yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import { useLayoutEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/common/Button";
import Input from "../components/common/Input";
import Loading from "../components/common/Loading";
const Signup = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        values
      );
      const message = response.data.message;
      toast.success(message);
      setTimeout(() => {
        navigate("/verify", {
          state: { email: values.email },
        });
      }, 1000);
    } catch (error) {
      const message = error.message;
      setLoading(false);
      toast.error(message);
    }

    resetForm();
  };

  const Schema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "Invalid email format"
      )
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Your password must contain at least 8 characters.")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: Schema,
    onSubmit: handleSubmit,
  });

  useLayoutEffect(() => {
    document.title = "Signup | Nova";
  }, []);

  return (
    <div className="py-32 bg-primaryBlack-dark font-beVietnamPro">
      <div className="w-10/12 sm:w-8/12 md:w-1/2 lg:w-1/3 mx-auto">
        <div className="">
          <h1 className="pb-4 md:pb-7 mx-auto flex justify-center text-2xl md:text-3xl lg:text-4xl tracking-wide font-DM text-white">
            Signup
          </h1>
          <Toaster />
          {loading ? (
            <Loading />
          ) : (
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-6"
            >
              <div className="flex flex-col gap-1 md:gap-3">
                <Input
                  type="text"
                  label="First Name"
                  placeHolder="First Name"
                  require={true}
                  name="firstName"
                  value={formik.values.firstName}
                  error={formik.touched.firstName && formik.errors.firstName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />

                <Input
                  type="text"
                  label="Last Name"
                  placeHolder="Last Name"
                  require={true}
                  name="lastName"
                  value={formik.values.lastName}
                  error={formik.touched.lastName && formik.errors.lastName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />

                <Input
                  type="email"
                  label="Email"
                  placeHolder="example@gmail.com"
                  require={true}
                  name="email"
                  value={formik.values.email}
                  error={formik.touched.email && formik.errors.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />

                <Input
                  type="password"
                  label="Password"
                  placeHolder="Password"
                  require={true}
                  name="password"
                  value={formik.values.password}
                  error={formik.touched.password && formik.errors.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />

                <div className="font-beVietnamPro text-center justify-center text-sm text-white/50 font-light">
                  If you already have an account please,
                  <Link
                    to="/login"
                    className="uppercase font-normal  tracking-wider transition-all duration-500 text-accentBlue-dark hover:text-accentBlue-medium"
                  >
                    {" "}
                    login
                  </Link>
                </div>
              </div>

              <Button type="submit" tertiary>
                Continue
              </Button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
export default Signup;
