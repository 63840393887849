import React from "react";
import HeroPic from "../../assets/images/imageOne.jpg";
import Button from "../common/Button";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
const Hero = () => {
  //scroll animation
  const leftAnimate = {
    offScreen: { x: -100 },
    onScreen: {
      x: 0,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };

  const rightAnimate = {
    offScreen: { x: 100 },
    onScreen: {
      x: 0,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };
  return (
    <motion.div
      transition={{ staggerChildren: 0.1 }}
      initial={"offScreen"}
      animate={"onScreen"}
      className="bg-gradient-to-r from-accentBlue-dark to-accentPurple-dark"
    >
      <div className="max-w-[1440px] flex justify-between flex-col md:flex-row items-start lg:items-center gap-14 w-11/12 mx-auto pt-32 md:pt-48 pb-28">
        <motion.div
          variants={leftAnimate}
          className="flex  flex-col font-beVietnamPro gap-4 md:gap-8 md:w-1/2 items-start"
        >
          <div className="flex flex-col gap-1 md:gap-3 ">
            <h1 className=" text-2xl md:text-4xl font-DM  xl:text-6xl text-white">
              Get Face time with Ethiopian Stars!
            </h1>
            <p className="text-white/90 w-11/12  text-sm md:text-base">
              Everyone's getting personalized videos. Don't pass up on this
              opportunity secure your first video for FREE on Nova!
            </p>
          </div>
          <Link to="categories">
            <Button primary> Browse a Star</Button>
          </Link>
        </motion.div>
        <motion.div variants={rightAnimate} className="w-8/12 md:w-4/12 flex ">
          <img src={HeroPic} alt="Hero pic" className="rounded-lg" />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Hero;
