import React, {useEffect, useState} from "react";
import Image from "../../assets/images/image.jpg";
import useFetchTalent from "../../hooks/useFetchTalent";

const RequestCards = ({request}) => {
	const [avatar, setAvatar] = useState(null);
	const {celebrities} = useFetchTalent(request.talent_id);
	// Updates the selectedImage state when profile_picture changes
	useEffect(() => {
		const imageUrl = ` ${
			celebrities.profile_picture === null ? Image : celebrities.profile_picture ?. replace("/home/novaet/", "https://")
		}`;
		setAvatar(imageUrl);
	}, [celebrities.profile_picture]);

	return (
		<tr className="text-center font-light ">
			<td className="px-2">
				<p className="px-2 capitalize text-xs md:text-sm">
					{
					celebrities.first_name
				}
					{
					celebrities.last_name
				} </p>
			</td>
			<td>
				<img src={avatar}
					alt=""
					className="w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 mx-auto  p-3 rounded-2xl object-cover "/>
			</td>

			<td className="px-2">
				<p className="capitalize text-xs md:text-sm">
					{
					request.recipient_type
				} </p>
			</td>
			<td className="px-2">
				<p className="capitalize  text-xs md:text-sm">
					{
					request.request_type
				}</p>
			</td>
			<td className="px-2 bg-accentPurple-medium/40">
				<p className=" capitalize text-xs md:text-sm">
					{
					request.rate
				}
					Birr</p>
			</td>
			<td className="px-2">
				<p className="capitalize text-xs md:text-sm text-green-300">
					{
					request.payment_status
				} </p>
			</td>
		</tr>
	);
};

export default RequestCards;
