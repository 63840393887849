import React from "react";
import ImageFour from "../../assets/images/photo_5814358113954939597_x.jpg";
import ImageTwo from "../../assets/images/imageTwo.png";
import ImageThree from "../../assets/images/imageThree.jpg";
import { motion } from "framer-motion";
const Explanation = () => {
  // scroll amination
  const rightCardAnimate = {
    offScreen: {
      x: 100,
      opacity: 0,
    },
    onScreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };

  const leftCardAnimate = {
    offScreen: {
      x: -100,
      opacity: 0,
    },
    onScreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };
  return (
    <div className="py-12 bg-primaryBlack-dark font-beVietnamPro ">
      <div className="w-11/12 max-w-[1440px] lg:w-10/12 mx-auto flex flex-col gap-12">
        <motion.div
          transition={{ staggerChildren: 0.1 }}
          initial={"offScreen"}
          whileInView={"onScreen"}
          viewport={{ once: true, amount: 0.2 }}
          className="flex flex-col items-start md:items-center justify-between gap-5 md:flex-row "
        >
          <motion.div
            variants={leftCardAnimate}
            className="md:w-1/2 flex flex-col  gap-2 md:gap-3"
          >
            <div className="flex gap-2 md:gap-3 items-center uppercase">
              <span className="bg-accentBlue-dark flex justify-center items-center font-bold w-6 h-6 md:w-7 md:h-7 rounded-full text-sm">
                1
              </span>
              <p className="text-sm  lg:text-lg uppercase text-accentBlue-dark tracking-wide">
                Search for a star
              </p>
            </div>
            <div className="flex flex-col gap-1 md:gap-2">
              <p className="text-white font-DM text-base lg:text-xl xl:text-2xl">
                Explore Your Star Power
              </p>
              <p className="text-white/90 font-light text-sm lg:text-base">
                Find all your favorite Ethiopian stars, from actors and
                musicians to comedians and influencer, all under one roof!
              </p>
            </div>
          </motion.div>
          <motion.div
            variants={rightCardAnimate}
            className="flex w-1/2 md:w-[30%]  items-end justify-end"
          >
            <img src={ImageTwo} alt="" className="rounded-lg" />
          </motion.div>
        </motion.div>

        <motion.div
          transition={{ staggerChildren: 0.1 }}
          initial={"offScreen"}
          whileInView={"onScreen"}
          viewport={{ once: true, amount: 0.2 }}
          className="flex flex-col items-start md:items-center justify-between gap-5 md:flex-row-reverse "
        >
          <motion.div
            variants={rightCardAnimate}
            className="md:w-1/2  flex flex-col gap-2 md:gap-3"
          >
            <div className="flex gap-2 md:gap-3 items-center uppercase">
              <span className="bg-accentBlue-dark flex justify-center items-center font-bold w-6 h-6 md:w-7 md:h-7 rounded-full text-sm">
                2
              </span>
              <p className="text-sm  lg:text-lg uppercase text-accentBlue-dark tracking-wide">
                Book Your Celeb for Shout out
              </p>
            </div>
            <div className="flex flex-col gap-1 md:gap-2">
              <p className="text-white font-DM text-base lg:text-xl xl:text-2xl">
                Shine a Light on Your Request
              </p>
              <p className="text-white/90 font-light text-sm lg:text-base">
                Want a birthday shout out, words of encouragement, or a special
                message for a loved one? Nova lets you personalize everything
              </p>
            </div>
          </motion.div>
          <motion.div
            variants={leftCardAnimate}
            className="flex items-center w-1/2 md:w-[30%]  justify-start"
          >
            <img src={ImageFour} alt="" className="rounded-lg" />
          </motion.div>
        </motion.div>
        <motion.div
          transition={{ staggerChildren: 0.1 }}
          initial={"offScreen"}
          whileInView={"onScreen"}
          viewport={{ once: true, amount: 0.2 }}
          className="flex flex-col items-start md:items-center justify-between gap-5 md:flex-row "
        >
          <motion.div
            variants={leftCardAnimate}
            className="md:w-1/2 flex flex-col  gap-2 md:gap-3"
          >
            <div className="flex gap-2 md:gap-3 items-center uppercase">
              <span className="bg-accentBlue-dark flex justify-center items-center font-bold w-6 h-6 md:w-7 md:h-7 rounded-full text-sm">
                3
              </span>
              <p className="text-sm  lg:text-lg text-accentBlue-dark tracking-wide">
                Unwrap Your Personalized Video
              </p>
            </div>
            <div className="flex flex-col gap-1 md:gap-2">
              <p className="text-white font-DM text-base  lg:text-xl xl:text-2xl">
                Witness Your Cosmic Connection:
              </p>
              <p className="text-white/90 font-light text-sm lg:text-base">
                Sit back, relax, and watch as your chosen celebrity creates a
                personalized video message just for YOU!
              </p>
            </div>
          </motion.div>
          <motion.div
            variants={rightCardAnimate}
            className="flex w-1/2 md:w-[30%]   items-end justify-end"
          >
            <img src={ImageThree} alt="" className="rounded-md" />
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Explanation;
