import axios from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Card from "../components/common/Card";
import DropDown from "../components/common/DropDown";
import Loading from "../components/common/Loading";
import { Link } from "react-router-dom";
import Error from "../components/common/Error";
const Categories = () => {
  const [celebrities, setCelebrities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filteredCelebrities, setFilteredCelebrities] = useState([]);

  const fetchAllCelebrities = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_CAT_URL}/all-talents`
      );
      const data = response.data;
      setCelebrities(data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };
  useEffect(() => {
    fetchAllCelebrities();
  }, []);

  const handleFilterChange = async (filter) => {
    if (filter === "all") {
      fetchAllCelebrities();
      setFilteredCelebrities([]);
    } else {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_CAT_URL}/talents/${filter}`
        );
        const data = await response.data;
        setFilteredCelebrities(data);
        setIsLoading(false);
        console.log(data);
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
    }
  };

  useLayoutEffect(() => {
    document.title = "Categories | Nova";
  }, []);
  const displayCelebs =
    filteredCelebrities.length > 0 ? filteredCelebrities : celebrities;

  return (
    <div className="py-32 bg-primaryBlack-dark font-beVietnamPro">
      {error ? (
        <Error error={error} />
      ) : (
        <div className="w-11/12 max-w-[1440px]  mx-auto">
          <div className="flex flex-col gap-1 md:gap-2">
            <h1 className=" font-DM capitalize  text-xl md:text-2xl lg:text-4xl tracking-wide text-white">
              All Categories
            </h1>
            <p className="text-white font-DM text-lg md:text-xl lg:text-2xl">
              Explore
            </p>
          </div>
          <div className="py-6">
            <DropDown
              label="Categories"
              data={[
                { name: "all" },
                { name: "actor" },
                { name: "musician" },
                { name: "tv-host" },
                { name: "comedian" },
                { name: "director" },
                { name: "influencer" },
              ]}
              onChange={handleFilterChange}
            />
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="pb-4 flex flex-col gap-3">
              <p className="text-white ">
                {displayCelebs.length} Result
                {displayCelebs.length > 1 ? "s" : ""}
              </p>
              <div className="grid  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-4 lg:gap-6 ">
                {displayCelebs.length > 0 &&
                  displayCelebs.map((items) => {
                    return (
                      <Link
                        to={`/categories/${items.talent_id}`}
                        key={items.talent_id}
                      >
                        <Card items={items} />
                      </Link>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Categories;
